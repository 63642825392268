import { Link } from 'react-router-dom';
import logo from './logo.svg';

function Home() {
  return (
    <div className="App">
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Mantenimiento de Gamuty</h1>
        <Link to="/users">Usuarios</Link>
    </header>
      
      
    </div>
  );
}

export default Home;