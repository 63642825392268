import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserList.css'; // Importa los estilos CSS


function UserList() {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://comuracingapi.sixerito.synology.me/users');
        //const response = await axios.get('http://localhost:8081/users');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setIsPopupOpen(true); // Abre el popup de edición
  };

  const handleClosePopup = () => {
    setSelectedRow(null);
    setIsPopupOpen(false); // Cierra el popup de edición
  };

  const handleUpdate = async () => {
    await axios.patch(`https://comuracingapi.sixerito.synology.me/users/${selectedRow._id}`, editedData);
    //await axios.patch(`http://localhost:8081/users/${selectedRow._id}`, editedData);
    handleClosePopup();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  return (
    <div>
      <h1>Lista de usuarios</h1>
      <div className="table-container">
        <table>
          <thead>
            <tr>
                {/* <th>ID</th>*/}
                <th>Username</th>
                <th>Email</th>
                <th>Fecha creación</th>
                <th>Nº Sesiones</th>
                <th>Fecha última sesión</th>
                <th>Ranking</th>
                <th>División</th>
                <th>Experiencia</th>
                <th>Nivel</th>
                <th>Instagram</th>
                <th>Twitch</th>
                <th>Youtube</th>
                <th>Caster?</th>
                <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} onClick={() => handleEdit(item)}>
                {/* <td>{item._id}</td>*/}
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{item.createdAt}</td>
                <td>{item.sessions}</td>
                <td>{item.lastSession}</td>
                <td>{item.rank}</td>
                <td>{item.division}</td>
                <td>{item.exp}</td>
                <td>{item.level}</td>
                <td>{item.instagram}</td>
                <td>{item.twitch}</td>
                <td>{item.youtube}</td>
                <td>{item.isCaster == true ? 'Sí' : 'No'}</td>
                <td>
                  <span className="edit-icon" onClick={() => handleEdit(item)}>✏️</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal para editar */}
      {isPopupOpen && (
        <div className="modal-container">
        <div className="modal-content">
          <span onClick={handleClosePopup}>Cerrar</span>
          <h2>Editar {selectedRow.username}</h2>
          <hr />
          <div className="input-container">
            <div>
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={editedData.username || (selectedRow && selectedRow.username) || ''}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Twitch</label>
              <input
                type="text"
                name="twitch"
                value={editedData.twitch || (selectedRow && selectedRow.twitch) || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button onClick={handleUpdate}>Actualizar</button>
        </div>
      </div>
      
      )}
    </div>
  );
}

export default UserList;
